import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import api from '../utils/Api'
import { matchRoutes, useLocation } from "react-router-dom"

const expand = 'md'

function OffcanvasExample() {
  const location = useLocation()

  const userStore = localStorage.getItem('@PLI:user')
  const user = userStore?JSON.parse(userStore):null

  return (
    <div className="shortcut-table">
        <Table bordered>
          <thead>
            <tr>
              <th>Shortcut</th>
              <th>Data Input</th>
              <th>Output</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#SPEND</td>
              <td>Current Quarter Spend +/- 5% vs. Previous Quarter Spend +/- 5%</td>
              <td>12%-17% yy in 4Q24 versus 14%-19% yy in 3Q24</td>
            </tr>
            <tr>
              <td>#PRICE</td>
              <td>Current Quarter Price +/- 5%</td>
              <td>7%-11% yy in 4Q24</td>
            </tr>
          </tbody>
        </Table>
    </div>
  );
}

export default OffcanvasExample;
