import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import {useState} from 'react'

import Home from "./Home"
import Login from "./Login"
import Schedule from "./Schedule"
import SourceInput from "./SourceInput"
import AdHoc from "./AdHoc"
import Past from "./Past"
import Pdf from "./Pdf"
import LogInHistory from "./LogInHistory"
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const tickers = [
  "AMZN",
  "CMCSA",
  "DIS",
  "GOOG - Search",
  "GOOG - YouTube",
  "META",
  "MGNI",
  "PARA",
  "PINS",
  "PUBM",
  "ROKU",
  "SNAP",
  "TTD",
  "Ad Hoc Report",
  "RDDT"
]

function App() {
  const userStore = localStorage.getItem('@PLI:user')
  const [user, setUser] = useState(userStore?JSON.parse(userStore):null)

  return (
    <BrowserRouter>
      <Routes>
      {user ? (
        <>
          {!userStore.readOnly &&
          <>
          <Route path='/' element={<Home />} />
          <Route path='/ad-hoc' element={<AdHoc />} />
          <Route path='/schedule' element={<Schedule />} />
          <Route path='/source-input' element={<SourceInput />} />
          <Route path='/log-in-history' element={<LogInHistory />} />
          </>
          }
          <Route path='/past-report' element={<Past />} />
          <Route path='/pdf' element={<Pdf />} />

          <Route path='*' element={<Navigate to='/' />} />
        </>
      ) : (
        <>
          <Route path='login' element={<Login onLogin={(u)=>{
            setUser(u)
          }} />} />
          <Route path='*' element={<Navigate to='/login' />} />
        </>
      )}
      </Routes>
    </BrowserRouter>
  )
}

export default App
